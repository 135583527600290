
















import { api } from "@/api/api";
import { ApiGetCustomerDto, ApiGetCourseParticipantStatusDto } from "@/api/generated/Api";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRoute } from "@/shared/useHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import ParticipantGeneralInfo from "@/components/course/details/participant/ParticipantGeneralInfo.vue";
import ParticipantStatusTable from "@/components/contacts/ParticipantStatusTable.vue";

export default defineComponent({
  name: "CustomerPersonPage",
  components: {
    BaseLayout,
    ParticipantGeneralInfo,
    ParticipantStatusTable,
  },

  setup() {
    const route = useRoute();
    const personId = +route.params.id;
    const personInfo = ref<ApiGetCustomerDto>();
    const participantStatuses = ref<ApiGetCourseParticipantStatusDto[]>();

    onMounted(async () => {
      await loadCustomerPerson();
      await loadCourseParticipantStatuses();
    });

    const loadCustomerPerson = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        personInfo.value = (await api.customer.getCustomerPersonByIdAsync(personId)).data;
      });
    };

    const loadCourseParticipantStatuses = async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        participantStatuses.value = (
          await api.courseparticipantstatus.getCourseParticipantStatusesByUserIdAsync(personId)
        ).data;
      });
    };

    return {
      personInfo,
      participantStatuses,
    };
  },
});
