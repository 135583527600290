




























import { ApiGetCourseParticipantStatusDto } from "@/api/generated/Api";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { getFormattedDatePeriod, getIsoDate } from "@/shared/helpers/dateHelpers";
import { useRoute } from "@/shared/useHelpers";
import { defineComponent, PropType } from "@vue/composition-api";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { parseISO, compareAsc } from "date-fns";
import { ParticipantRoles } from "@/shared/enums/ParticipantRoles.enum";

export default defineComponent({
  name: "ParticipantStatusTable",
  components: { BaseTableFiltered },
  props: {
    participantStatuses: {
      type: Array as PropType<ApiGetCourseParticipantStatusDto[]>,
      required: true,
    },
  },
  setup() {
    const route = useRoute();

    const toCourseDashboard = (courseId: number) => ({
      name: SingleCourseRouteNames.CourseDashboard,
      params: { id: courseId.toString() },
      query: { participantId: route.params.id },
    });

    const getRole = (roleName: string) => {
      if (roleName === ParticipantRoles.Student) {
        return ParticipantRoles.Student;
      }
      return ParticipantRoles.Resource;
    };

    return {
      headers,
      toCourseDashboard,
      getFormattedDatePeriod,
      getRole,
    };
  },
});

const headers = [
  {
    text: "ID",
    value: "courseId",
  },
  {
    text: "Navn",
    value: "courseName",
  },
  {
    text: "Tidspunkt",
    value: "startDate",
    sort: (a: string, b: string) => {
      const dateLeft = parseISO(getIsoDate(a));
      const dateRight = parseISO(getIsoDate(b));
      return compareAsc(dateLeft, dateRight);
    },
  },
  {
    text: "Status",
    value: "courseStatus",
  },
  {
    text: "Deltakerstatus",
    value: "participantStatus",
  },
  {
    text: "Rolle",
    value: "roleName",
  },
  {
    text: "Handlinger",
    value: "actions",
    sortable: false,
  },
];
